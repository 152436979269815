<template>

    <div @click="clickOnIcon" class="close-button">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.208" height="22.157" viewBox="0 0 22.208 22.157">
            <g id="Сгруппировать_22543" data-name="Сгруппировать 22543" transform="translate(11.024 -6.94) rotate(45)">
                <line id="Линия_3" data-name="Линия 3" x2="25.115" transform="translate(0.112 12.702)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                <line id="Линия_4" data-name="Линия 4" x2="25.334" transform="translate(12.816) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
            </g>
        </svg>

    </div>

</template>

<script>
export default {
    name: "CloseButton",
    methods: {
        clickOnIcon() {
            this.$emit('click')
        }
    }

}
</script>

<style scoped>
.close-button {
    width: 48px;
    height: 48px;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
