<template>
    <app-transition>
        <Dialog @close="handleCancel">
            <v-row>
                <v-col class="ConfirmEndingStream-message">
                    <p>{{ $t('dialogs.confirm_ending_stream') }}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn outlined block height="50" @click="handleConfirm">{{ $t('dialogs.yes') }}</v-btn>
                </v-col>
                <v-col>
                    <v-btn outlined block height="50" @click="handleCancel">{{ $t('dialogs.no') }}</v-btn>
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>

<script>
import Dialog from "@/components/app/form/Dialog"
import {CURRENCY_SYMBOL} from "@/configs/constants"

export default {
    name: "ConfirmEndingStreamDialog",
    methods: {
        handleCancel() {
            this.$emit('cancel')
        },
        handleConfirm() {
            this.$emit('confirm')
        },
    },
    components: {
        Dialog,
    }
}
</script>
<style scoped lang="scss">
.ConfirmEndingStream {
    &-message {
        font-weight: bold;
        font-size: 20px;
    }
}

.v-btn {
    text-transform: capitalize;
    font-size: 1em !important;
    padding: 10px 28px !important;
}
</style>
